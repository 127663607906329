<template>
  <div>
    <ParallaxImageTitle :imagePath="require('@/assets/images/www/cards/5-1400.jpg')" title="PRIVATE  PILOT LICENCE" subtitle="Pilot in Command or co-pilot for private operations" />
    <div class="program-description">
      <h1>What is a Private Pilot Licence (PPL)?</h1>
      <p>
         The Private Pilot Licence allows you as the holder to fly an aircraft around Australia as Pilot in Command or Co-Pilot in Private operations.
      </p>

      <br>

      <h1>Requirements</h1>
      <div class="list-container">
        <ul class="program-list">
          <li><b>Aeronautical experience:</b> 40 hours</li>
          <li><b>Flight time as pilot:</b> 35 hours</li>
          <li><b>Solo flight time:</b> 10 hours</li>
          <li><b>Solo cross-country:</b> 5 hours</li>
          <li><b>Dual instrument time:</b> 2 hours</li>
          <li><b>Dual instrument flight time:</b> 1 hour</li>
        </ul>
      </div>

      <br>

      <p>
        We do our Private Pilot Licences in either our Cessna 172 or our German Aquila A210 with constant speed unit.
      </p>
    </div>
  </div>
</template>

<script>
import ParallaxImageTitle from '@/components/ParallaxImageTitle.vue'

export default {
  components: {
    ParallaxImageTitle
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
@include program-page;
</style>
